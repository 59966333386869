import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#katar"> &gt; Poradnik(Katar)</a>
            <a href="/poradnik/katar/poznaj_rodzaje_kataru/" className="sel">
              {" "}
              &gt; Poznaj rodzaje kataru
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Poznaj rodzaje kataru</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <b>
                    Katar objawia się nadmierną produkcją śluzu w nosie. Powodów
                    takiej sytuacji jest kilka. Nieżyt nosa może pojawić się
                    wraz z przeziębieniem i grypą lub po kontakcie z alergenami,
                    bądź substancjami drażniącymi. W związku z tym wyróżniamy
                    katar wirusowy, bakteryjny, alergiczny i
                    naczynioruchowy.&nbsp;
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Katar pojawiający się w sezonie przeziębieniowym, na ogół jest
                  wywołany zakażeniem wirusowym. To właśnie z nim najczęściej
                  zmagamy się jesienią oraz wiosną. Osłabienie odporności
                  następuje w wyniku kontaktu ze szkodliwymi drobnoustrojami
                  m.in. rinowirusami (wirusy wywołujące nieżyt nosa).
                  Chorobotwórcze mikroorganizmy są przenoszone drogą
                  powietrzno-kropelką, zwykle podczas przebywania w jednym
                  pomieszczeniu z kichającą lub kaszlącą osobą. Gdy wnikną one
                  do naszego organizmu, zaczynają się namnażać i powodują stan
                  zapalny. W przypadku{" "}
                  <a
                    href="/slowniczek/#zapalenia_blony_sluzowej_nosa_i_zatok_przynosowych"
                    target="_blank"
                  >
                    zapalenia błony śluzowej nosa
                  </a>{" "}
                  jej naczynia krwionośne zostają nadmiernie wypełnione krwią,
                  rozszerzają się, a śluzówka puchnie. To prawidłowa reakcja
                  obronna walczącego organizmu. Zaczynamy mieć problemy z
                  oddychaniem przez nos i bardzo często odczuwamy także ból
                  głowy. Początkowo zalegająca w nosie{" "}
                  <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                    wydzielina
                  </a>{" "}
                  jest wodnista (wiąże się to z przekrwieniem naczyń
                  krwionośnych nosa), a wraz z rozwojem choroby gęstnieje.&nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Katar bakteryjny pojawia się na skutek nadkażenia bakteryjnego
                  uszkodzonej przez wirusy błony śluzowej nosa. Obecność ropy i
                  śluzu w przewodach nosowych sprawia, że wydzielina zmienia
                  swój kolor – z bezbarwnego, na żółtawy lub zielonkawy.&nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Katar sienny (katar alergiczny) wymaga dodatkowego udrażniania nosa. SUDAFED®"
                    src="/web/kcfinder/uploads/images/shutterstock_272161175.jpg"
                    style={{
                      width: "380px",
                      height: "290px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                    }}
                  />
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Alergiczny nieżyt nosa (nazywany katarem siennym) nie jest
                  zaraźliwy. W przypadku stałego kontaktu z alergenem może być
                  przewlekły, sezonowy lub całoroczny. U niektórych osób
                  występuje w momencie zetknięcia się z roztoczami kurzu
                  domowego, sierścią zwierząt lub dymem. Inni zmagają się z
                  katarem alergicznym w sytuacji, gdy rośliny lub drzewa
                  zaczynają kwitnąć. U alergików pojawia się wodnista,
                  bezbarwna, dość obfita wydzielina, która nie zmienia swojej
                  konsystencji. Charakterystyczne dla{" "}
                  <a href="/slowniczek/#alergia" target="_blank">
                    alergii
                  </a>{" "}
                  jest również zapalenie spojówek, zaczerwienienie oczu,
                  łzawienie, zaburzenia snu i koncentracji, natomiast stosunkowo
                  rzadko pojawia się podwyższona temperatura. W trakcie alergii
                  bardzo często stosujemy leki przeciwhistaminowe. Warto jednak
                  pamiętać, że łagodzą one świąd i hamują wyciek z nosa, ale nie
                  odblokowują go. Niezbędne jest więc zastosowanie leków, które
                  dodatkowo udrożnią nos (np. pod marką Sudafed
                  <span className="s1">
                    <sup>®</sup>
                  </span>
                  ).
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Katar naczynioruchowy nie jest wynikiem zakażenia czy
                  uczulenia. Może być spowodowany szybką zmianą temperatury
                  (m.in. po wejściu z dworu do ciepłego pomieszczenia np.
                  autobusu), zjedzeniem ostrej potrawy, uprawianiem aktywności
                  fizycznej, silną ekspozycją na światło lub zmianą pozycji z
                  pionowej na poziomą.&nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Z uczuciem zatkanego nosa można walczyć stosując odpowiednie
                  leki. Często podczas kataru odczuwamy suchość w nosie, dlatego
                  warto sięgnąć po produkty zapewniające nie tylko odblokowanie
                  nosa, ale także odpowiednie nawilżenie śluzówki. Dobrym
                  rozwiązaniem będzie użycie leku{" "}
                  <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                    Sudafed<sup>®</sup> XyloSpray HA
                  </a>
                  , ponieważ zawiera on aż trzy substancje o działaniu
                  nawilżającym:{" "}
                  <a href="/slowniczek/#kwas_hialuronowy" target="_blank">
                    kwas hialuronowy
                  </a>
                  ,{" "}
                  <a href="/slowniczek/#sorbitol" target="_blank">
                    sorbitol
                  </a>{" "}
                  i{" "}
                  <a href="/slowniczek/#glicerol" target="_blank">
                    glicerol
                  </a>
                  , a co więcej nie zawiera konserwantów, dzięki czemu nie
                  podrażnia błony śluzowej nosa oraz nie nasila objawów
                  alergicznego nieżytu nosa, które mogą wywoływać konserwanty
                  <sup>1</sup>. Dla osób, które preferują dyskretną formę
                  aplikacji sprawdzą się{" "}
                  <a href="/produkty/sudafed_tabletki/" target="_blank">
                    tabletki Sudafed<sup>®</sup>
                  </a>
                  , które dzięki zawartości{" "}
                  <a href="/slowniczek/#pseudoefedryna" target="_blank">
                    pseudoefedryny
                  </a>
                  , eliminują uczucie ucisku w głowie i zatokach<sup>2</sup>,
                  zmniejszając{" "}
                  <a href="/slowniczek/#obrzek_blony_sluzowej" target="_blank">
                    obrzęk błony śluzowej nosa
                  </a>{" "}
                  oraz ilość nagromadzonej w górnych drogach oddechowych
                  wydzieliny.&nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Lebe E, Baka M, Yavaşoğlu A, Aktuğ H, Ateş U, Uyanikgil Y.
                    Effects of preservatives in nasal formulations on the
                    mucosal integrity: an electron microscopic study.
                    Pharmacology. 2004 Oct;72(2):113-20.
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    2 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej
                    nosa.&nbsp;
                  </span>
                </div>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Bibliografia:
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    A. Emeryk, P. Rapiejko, A. Lipiec,{" "}
                    <i>Alergiczny nieżyt nosa</i>, Poznań 2013.
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    P. Rapiejko, A. Lipiec, <i>Podział nieżytów nosa</i>, [w:]
                    „Alergia”, 2013, nr 2, s. 6-8.
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak prawidłowo oczyszczać nos podczas kataru?
                  </span>
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_152527238.jpg")',
                    }}
                  />
                  <span className="title">Podejmij szybką walkę z katarem</span>
                </a>
                <a
                  href="/poradnik/katar/katar_alergiczny_-_poznaj_i_zapobiegaj"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_1206612040.jpg")',
                    }}
                  />
                  <span className="title">
                    Katar alergiczny - poznaj i zapobiegaj
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
